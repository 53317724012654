<template>
    <div>
        <v-timeline-item
            :class="margin"
            :color="color"
            :icon="icon"
            fill-dot
        >
            <v-layout justify-space-between>
                <v-flex xs7>
                    <slot></slot>
                </v-flex>
                <v-flex grey--text text-xs-right v-text="date" xs5></v-flex>
            </v-layout>
        </v-timeline-item>
        <div v-if="action != null">
            <v-timeline-item class="mb-5" hide-dot>
                <div v-if="action === 1">
                    <v-btn :loading="resendConfirmationIsLoading" @click="resendConfirmationEmail" class="mx-0"
                           color="white">{{content.resend}}
                    </v-btn>
                </div>
                <div v-else-if="action === 2">
                    <v-btn :loading="resendSuppressionIsLoading" @click="resendSuppressionEmail" class="mx-0"
                           color="white">{{content.resend}}
                    </v-btn>
                </div>
            </v-timeline-item>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: ['color', 'datetime', 'icon', 'cause', 'id', 'locale'],
        data: () => ({
            date: null,
            margin: "mb-5",
            action: null,
            resendConfirmationIsLoading: false,
            resendSuppressionIsLoading: false,
            content: null,
            dictionary: {
                en: {
                    content: {
                        resend: "Resend Email"
                    }
                },
                fr: {
                    content: {
                        resend: "Renvoyer l'Email"
                    }
                },
                es: {
                    content: {
                        resend: "Reenviar el e-mail"
                    }
                },
                it: {
                    content: {
                        resend: "Reinvia l'e-mail"
                    }
                }
            }
        }),
        created() {
            switch (this.locale) {
                case 'fr':
                    this.content = this.dictionary.fr.content;
                    moment.locale('fr');
                    break;
                case 'es':
                    this.content = this.dictionary.es.content;
                    moment.locale('es');
                    break;
                case 'it':
                    this.content = this.dictionary.it.content;
                    moment.locale('it');
                    break;
                default:
                    this.content = this.dictionary.en.content;
                    moment.locale('en');
                    break;
            }
        },
        mounted() {
            if (this.datetime !== "") {
                this.date = moment(this.datetime).format("LL");
            } else {
                this.date = this.datetime;
            }
            if (this.cause === 'pendingConfirmation') {
                this.action = 1
            } else if (this.cause === 'pendingDeletion') {
                this.action = 2
            }
            if (this.action != null) {
                this.margin = "mb-1";
            }
        },
        methods: {
            resendConfirmationEmail() {
                this.resendConfirmationIsLoading = true;
                axios.post('/api/signatories/' + this.id + '/resend-confirmation', {
                    locale: this.locale
                })
                    .then((response) => {
                        this.resendConfirmationIsLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.resendConfirmationIsLoading = false;
                    })
            },
            resendSuppressionEmail() {
                this.resendSuppressionIsLoading = true;
                axios.post('/api/signatories/' + this.id + '/resend-suppression', {
                    locale: this.locale
                })
                    .then((response) => {
                        this.resendSuppressionIsLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.resendSuppressionIsLoading = false;
                    })
            }
        }
    }
</script>
