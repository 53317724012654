<template>
    <v-card>
        <v-card-title primary-title>
            <v-flex>
                <h1 class="font-weight-thin text-xs-center display-1">{{content.title}}</h1>
            </v-flex>
        </v-card-title>
        <v-card-text>
            <v-form lazy-validation ref="form" v-model="valid">
                <v-container fluid grid-list-xl>
                    <v-layout justify-space-between row wrap>
                        <v-flex md6 xs12>
                            <v-text-field
                                :error-messages="errors.collect('name')" :label="content.name" autofocus
                                data-vv-name="name"
                                required
                                solo
                                v-model="form.name"
                                v-validate="'required|min:2|max:50'"
                            ></v-text-field>
                        </v-flex>
                        <v-flex md6 xs12>
                            <v-text-field :error-messages="errors.collect('email')" :label="content.email"
                                          data-vv-name="email"
                                          required
                                          solo
                                          type="email"
                                          v-model="form.email"
                                          v-validate="'required|email'"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout justify-space-between row wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.collect('subject')" counter="40"
                                          data-vv-name="subject"
                                          :label="content.subject"
                                          required
                                          solo
                                          v-model="form.subject"
                                          v-validate="'required|min:2|max:40'"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-textarea :error-messages="errors.collect('content')" counter="1000"
                                        data-vv-name="content" height="145"
                                        :label="content.message"
                                        required
                                        solo
                                        v-model="form.content"
                                        v-validate="'required|min:2|max:1000'"
                            ></v-textarea>
                        </v-flex>
                    </v-layout>
                    <v-layout justify-space-around row wrap>
                        <v-hover>
                            <v-btn :class="`elevation-${hover ? 10 : 2}`" :loading="submitIsLoading" @click="submitForm"
                                   color="success"
                                   large
                                   slot-scope="{ hover }"
                            >
                                <v-icon>mail</v-icon>&nbsp;{{content.send}}
                            </v-btn>
                        </v-hover>
                    </v-layout>
                </v-container>
            </v-form>
            <v-layout row>
                <v-flex xs12>
                    <v-alert
                        :value="successAlert"
                        dismissible
                        outline
                        transition="scale-transition"
                        type="success"
                    >
                        <v-layout align-center justify-space-between row wrap>
                            <v-flex class="text-xs-left">
                                {{content.success}}
                            </v-flex>
                        </v-layout>
                    </v-alert>
                </v-flex>
            </v-layout>
            <v-snackbar
                color="error"
                multi-line
                top
                v-model="globalResponseError"
            >
                {{ globalResponseErrorMessage }}
                <v-btn
                    @click="globalResponseError = false"
                    dark flat icon
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-snackbar>
        </v-card-text>
    </v-card>
</template>

<script>
    import Vue from 'vue';
    import VeeValidate from 'vee-validate';

    Vue.use(VeeValidate);

    export default {
        name: "ContactFormComponent",
        $_veeValidate: {
            validator: 'new'
        },
        props: ['locale'],
        data: () => ({
            valid: false,
            submitIsLoading: false,
            form: {
                name: null,
                email: null,
                subject: null,
                content: null
            },
            successAlert: false,
            globalResponseError: false,
            globalResponseErrorMessage: null,
            content: null,
            dictionary: {
                en: {
                    content: {
                        title: "Contact Us",
                        name: "Name",
                        email: "Email",
                        subject: "Subject",
                        message: "Message",
                        send: "Send",
                        success: "Your message was sent, expect a response soon!",
                        error: "An unexpected error happened. We're working on it."
                    },
                    errors: {
                        custom: {
                            name: {
                                required: "Name cannot be empty.",
                                min: 'There must be at least 2 characters.',
                                max: 'There must not be more than 50 characters.'
                            },
                            email: {
                                required: "Email cannot be empty.",
                                email: "Should be a valid email address."
                            },
                            subject: {
                                required: "Subject cannot be empty.",
                                min: 'There must be at least 2 characters.',
                                max: 'There must not be more than 40 characters.'
                            },
                            content: {
                                required: "Message cannot be empty.",
                                min: 'There must be at least 2 characters.',
                                max: 'There must not be more than 1000 characters.'
                            }
                        }
                    }
                },
                fr: {
                    content: {
                        title: "Contactez-nous",
                        name: "Nom",
                        email: "Email",
                        subject: "Objet",
                        message: "Message",
                        send: "Envoyer",
                        success: "Votre message a été envoyé, attendez-vous à une réponse rapidement !",
                        error: "Une erreur inattendue est survenue. Nous travaillons à régler le problème."
                    },
                    errors: {
                        custom: {
                            name: {
                                required: "Ne peut pas être vide.",
                                min: 'Doit avoir au moins 2 caractères.',
                                max: 'Ne doit pas avoir plus de 50 caractères.'
                            },
                            email: {
                                required: "Ne peut pas être vide.",
                                email: "Merci d'indiquer une adresse email valide."
                            },
                            subject: {
                                required: "Ne peut pas être vide.",
                                min: 'Doit avoir au moins 2 caractères.',
                                max: 'Ne doit pas avoir plus de 40 caractères.'
                            },
                            content: {
                                required: "Ne peut pas être vide.",
                                min: 'Doit avoir au moins 2 caractères.',
                                max: 'Ne doit pas avoir plus de 1000 caractères.'
                            }
                        }
                    }
                },
                es: {
                    content: {
                        title: "Contáctanos",
                        name: "Nombre",
                        email: "Email",
                        subject: "Asunto",
                        message: "Mensaje",
                        send: "Enviar",
                        success: "Tu mensaje ha sido enviado, ¡recibirás una respuesta pronto!",
                        error: "Ocurrió un error inesperado. Estamos trabajando en ello."
                    },
                    errors: {
                        custom: {
                            name: {
                                required: "Este campo no puede estar vacío.",
                                min: 'Son necesarios al menos 2 caracteres.',
                                max: 'No puede sobrepasar los 50 caracteres.'
                            },
                            email: {
                                required: "Este campo no puede estar vacío.",
                                email: "Por favor, introduce una dirección email válida."
                            },
                            subject: {
                                required: "Este campo no puede estar vacío.",
                                min: 'Son necesarios al menos 2 caracteres.',
                                max: 'No puede sobrepasar los 40 caracteres.'
                            },
                            content: {
                                required: "Este campo no puede estar vacío.",
                                min: 'Son necesarios al menos 2 caracteres.',
                                max: 'No puede sobrepasar los 1000 caracteres.'
                            }
                        }
                    }
                },
                it: {
                    content: {
                        title: "Contattaci",
                        name: "Nome",
                        email: "Email",
                        subject: "Oggetto",
                        message: "Messagio",
                        send: "Invia",
                        success: "Il tuo messagio è stato inviato, riceverai presto una risposta!",
                        error: "Si è verificato un errore inaspettato. Ci stiamo lavorando."
                    },
                    errors: {
                        custom: {
                            name: {
                                required: "Non può essere vuoto.",
                                min: 'Ci devono essere almeno 2 caratteri.',
                                max: 'Non devono esserci più di 50 caratteri.'
                            },
                            email: {
                                required: "Non può essere vuoto.",
                                email: "L'indirizzo e-mail deve essere valido."
                            },
                            subject: {
                                required: "Non può essere vuoto.",
                                min: 'Ci devono essere almeno 2 caratteri.',
                                max: 'Non devono esserci più di 40 caratteri.'
                            },
                            content: {
                                required: "Non può essere vuoto.",
                                min: 'Ci devono essere almeno 2 caratteri.',
                                max: 'Non devono esserci più di 1000 caratteri.'
                            }
                        }
                    }
                },
            }
        }),
        created() {
            switch (this.locale) {
                case 'fr':
                    this.content = this.dictionary.fr.content;
                    this.$validator.localize('fr', this.dictionary.fr.errors);
                    break;
                case 'es':
                    this.content = this.dictionary.es.content;
                    this.$validator.localize('es', this.dictionary.es.errors);
                    break;
                case 'it':
                    this.content = this.dictionary.it.content;
                    this.$validator.localize('it', this.dictionary.it.errors);
                    break;
                default:
                    this.content = this.dictionary.en.content;
                    this.$validator.localize('en', this.dictionary.en.errors);
                    break;
            }
        },
        methods: {
            resetForm() {
                this.form.name = null;
                this.form.email = null;
                this.form.subject = null;
                this.form.content = null;
                this.$validator.reset();
            },
            submitForm() {
                this.$validator.validateAll()
                    .then((result => {
                        if (result) {
                            this.submitIsLoading = true;
                            axios.post('/en/contact', {
                                name: this.form.name,
                                email: this.form.email,
                                subject: this.form.subject,
                                content: this.form.content
                            })
                                .then((response) => {
                                    console.log(response.data);
                                    this.resetForm();
                                    this.successAlert = true;
                                })
                                .catch((error) => {
                                    if (error.response.data.message === "") {
                                        this.globalResponseErrorMessage = this.content.error;
                                    } else {
                                        this.globalResponseErrorMessage = error.response.data.message;
                                    }
                                    this.globalResponseError = true;
                                    if (error.response.data.errors) {
                                        if (error.response.data.errors.name) {
                                            this.$validator.errors.add({
                                                field: 'name',
                                                msg: error.response.data.errors.name
                                            });
                                        }
                                        if (error.response.data.errors.email) {
                                            this.$validator.errors.add({
                                                field: 'email',
                                                msg: error.response.data.errors.email
                                            });
                                        }
                                        if (error.response.data.errors.subject) {
                                            this.$validator.errors.add({
                                                field: 'subject',
                                                msg: error.response.data.errors.subject
                                            });
                                        }
                                        if (error.response.data.errors.content) {
                                            this.$validator.errors.add({
                                                field: 'content',
                                                msg: error.response.data.errors.content
                                            });
                                        }
                                    }
                                })
                                .finally(() => {
                                    this.submitIsLoading = false;
                                })
                        }
                    }))
                    .catch(err => {
                        alert(err);
                    })
            }
        }
    }
</script>

<style scoped>

</style>
