<template>
    <div>
        <v-form
            data-vv-scope="signingForm"
            lazy-validation
            ref="form"
            v-model="signingForm.valid"
        >
            <v-container fluid grid-list-xl>
                <v-layout justify-space-between row wrap>
                    <v-flex md6 xs12>
                        <v-text-field
                            :error-messages="errors.collect('signingFirstName', 'signingForm')"
                            :label="content.signingForm.firstName"
                            data-vv-name="signingFirstName"
                            required
                            v-model="signingForm.form.first_name"
                            v-validate="'required|min:2|max:255'"
                        ></v-text-field>
                    </v-flex>
                    <v-flex md6 xs12>
                        <v-text-field
                            :error-messages="errors.collect('signingLastName', 'signingForm')"
                            :label="content.signingForm.lastName"
                            data-vv-name="signingLastName"
                            required
                            v-model="signingForm.form.last_name"
                            v-validate="'required|min:2|max:255'"
                        ></v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout row wrap>
                    <v-flex md4 sm6 xs12>
                        <v-select
                            :error-messages="errors.collect('signingCountry', 'signingForm')"
                            :items="countries"
                            :label="content.signingForm.country"
                            data-vv-name="signingCountry"
                            item-text="name"
                            item-value="id"
                            required
                            v-model="signingForm.form.country"
                            v-on:input="loadInstitutions"
                            v-validate="'required'"
                        ></v-select>
                    </v-flex>
                    <v-flex md8 sm6 xs12>
                        <v-autocomplete
                            :disabled="signingForm.autocompleteIsDisabled"
                            :error-messages="errors.collect('signingInstitution', 'signingForm')"
                            :items="signingForm.form.institutions"
                            :label="content.signingForm.institution"
                            :loading="signingForm.autocompleteIsLoading"
                            :placeholder="signingForm.autocompletePlaceholder"
                            :search-input.sync="signingForm.search"
                            data-vv-name="signingInstitution"
                            hide-details
                            hide-no-data
                            item-text="name"
                            item-value="id"
                            return-object
                            v-model="signingForm.form.institution"
                            v-on:input="setEmailFormat"
                            v-validate="'required'"
                        ></v-autocomplete>
                    </v-flex>
                </v-layout>

                <v-layout row wrap>
                    <v-flex>
                        <v-text-field
                            :disabled="signingForm.emailIsDisabled"
                            :error-messages="errors.collect('signingEmail', 'signingForm')"
                            :hint="signingForm.emailHint"
                            :label="content.signingForm.email"
                            data-vv-name="signingEmail"
                            persistent-hint
                            required
                            v-model="signingForm.form.email"
                            v-validate="'required|email|existingFormat'"
                        ></v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout row wrap>
                    <v-flex>
                        <v-checkbox
                            :error-messages="errors.collect('signingCheckbox', 'signingForm')"
                            :label="content.signingForm.checkbox"
                            color="success"
                            data-vv-name="signingCheckbox"
                            required
                            type="checkbox"
                            v-model="signingForm.form.checkbox"
                            v-validate="'required'"
                        ></v-checkbox>
                    </v-flex>
                </v-layout>

                <v-layout justify-space-around row>
                    <v-hover>
                        <v-btn
                            :class="`elevation-${hover ? 10 : 2}`"
                            :loading="signingForm.submitIsLoading" @click="submitSigningForm" color="success"
                            large
                            slot-scope="{ hover }"
                        >
                            {{content.signingForm.submit}}
                        </v-btn>
                    </v-hover>
                </v-layout>

                <v-layout row>
                    <v-flex xs12>
                        <v-alert
                            :value="signingForm.isSuccess"
                            dismissible
                            outline
                            transition="scale-transition"
                            type="success"
                        >
                            <v-layout align-center justify-space-between row wrap>
                                <v-flex class="text-xs-left">
                                    {{content.signingForm.alertSuccess}}
                                </v-flex>
                                <v-btn :loading="signingForm.resendIsLoading" @click="resendConfirmationEmail"
                                       class="green--text text--darken-3" color="green lighten-4" depressed small>
                                    {{content.signingForm.resendButton}}
                                </v-btn>
                            </v-layout>
                        </v-alert>
                        <v-alert
                            :value="signingForm.isConfirmed"
                            dismissible
                            outline
                            transition="scale-transition"
                            type="success"
                        >
                            <v-layout align-center justify-space-between row wrap>
                                <v-flex class="text-xs-left">
                                    {{content.signingForm.alertConfirmed}}
                                </v-flex>
                            </v-layout>
                        </v-alert>
                        <v-alert
                            :value="signingForm.isPendingConfirmation"
                            outline
                            transition="scale-transition"
                            type="warning"
                        >
                            <v-layout align-center justify-space-between row wrap>
                                <v-flex class="text-xs-left">
                                    {{content.signingForm.alertPending}}
                                </v-flex>
                                <v-btn :loading="signingForm.resendIsLoading" @click="resendConfirmationEmail"
                                       class="orange--text text--darken-3" color="orange lighten-4" depressed small>
                                    {{content.signingForm.resendButton}}
                                </v-btn>
                            </v-layout>
                        </v-alert>
                        <v-alert
                            :value="signingForm.isPendingDeletion"
                            outline
                            transition="scale-transition"
                            type="warning"
                        >
                            <v-layout align-center justify-space-between row wrap>
                                <v-flex class="text-xs-left">
                                    {{content.signingForm.alertSuppression}}
                                </v-flex>
                                <v-btn :loading="signingForm.resendIsLoading" @click="resendSuppressionEmail"
                                       class="orange--text text--darken-3" color="orange lighten-4" depressed small>
                                    {{content.signingForm.resendButton}}
                                </v-btn>
                            </v-layout>
                        </v-alert>
                        <v-alert
                            :value="signingForm.isUnexpected"
                            outline
                            transition="scale-transition"
                            type="error"
                        >
                            <v-layout align-center justify-space-between row wrap>
                                <v-flex class="text-xs-left">
                                    {{content.signingForm.alertError}}
                                </v-flex>
                            </v-layout>
                        </v-alert>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-form>
        <v-container fluid grid-list-xl>
            <v-layout justify-space-between row wrap>
                <v-flex xs12>
                    <v-alert
                        :value="addInstitutionAlert"
                        color="info"
                        icon="help"
                        outline
                        transition="scale-transition"
                        value="true"
                    >
                        <v-layout align-center justify-space-between row wrap>
                            <v-flex class="text-xs-left">
                                {{content.institutionForm.alertAdd}}
                            </v-flex>
                            <v-btn @click="addInstitutionForm = true" class="blue--text text--darken-3"
                                   color="blue lighten-4" depressed small>{{content.institutionForm.alertAddButton}}
                            </v-btn>
                        </v-layout>
                    </v-alert>
                </v-flex>
                <v-dialog max-width="800px" persistent v-model="addInstitutionForm">
                    <v-form
                        data-vv-scope="institutionForm"
                        lazy-validation
                        ref="form"
                        v-model="institutionForm.valid"
                    >
                        <v-card class="px-3 pb-3">
                            <v-card-title primary-title>
                                <h5 class="headline mb-2">{{content.institutionForm.formTitle}}</h5>
                            </v-card-title>
                            <v-card-text class="py-0">
                                <v-container grid-list-md>
                                    <v-layout wrap>
                                        <v-flex md4 sm6 xs12>
                                            <v-select
                                                :error-messages="errors.collect('institutionCountry', 'institutionForm')"
                                                :items="countries"
                                                :label="content.institutionForm.country+'*'"
                                                data-vv-name="institutionCountry"
                                                item-text="name"
                                                item-value="id"
                                                required
                                                v-model="institutionForm.form.country"
                                                v-on:input="loadInstitutionsName"
                                                v-validate="'required'"
                                            ></v-select>
                                        </v-flex>
                                        <v-flex md8 sm6 xs12>
                                            <v-text-field
                                                :disabled="institutionForm.nameIsDisabled"
                                                :error-messages="errors.collect('institutionName', 'institutionForm')"
                                                :label="content.institutionForm.name+'*'"
                                                :loading="institutionForm.nameValidationSpinner"
                                                data-vv-name="institutionName"
                                                required
                                                v-model="institutionForm.form.name"
                                                v-validate="'required|min:2|max:255|doesntAlreadyExist'"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex class="mb-0" xs12>
                                            <p class="green--text mt-2 mb-0">
                                                {{content.institutionForm.domainsExplanation}}</p>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-combobox
                                                :error-messages="errors.collect('institutionEmailFormats', 'institutionForm')"
                                                :hint="content.institutionForm.domainsHint"
                                                :label="content.institutionForm.domains"
                                                :search-input.sync="institutionForm.email_format"
                                                class="pt-0"
                                                data-vv-name="institutionEmailFormats"
                                                hide-selected
                                                multiple
                                                persistent-hint
                                                small-chips
                                                v-model="institutionForm.form.email_formats"
                                                v-validate="{min: 5, max: 250, regex: '^([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$' }"
                                            >
                                                <template slot="no-data">
                                                    <v-list-tile>
                                                        <v-list-tile-content>
                                                            <v-list-tile-title>
                                                                <kbd>{{content.institutionForm.domainsAddHint1}}</kbd>
                                                                {{content.institutionForm.domainsAddHint2}} "<strong>{{
                                                                institutionForm.email_format }}</strong>".
                                                            </v-list-tile-title>
                                                        </v-list-tile-content>
                                                    </v-list-tile>
                                                </template>
                                            </v-combobox>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-text-field
                                                :error-messages="errors.collect('institutionCreatedBy', 'institutionForm')"
                                                :hint="content.institutionForm.emailHint"
                                                :label="content.institutionForm.email+'*'"
                                                data-vv-name="institutionCreatedBy"
                                                persistent-hint
                                                required
                                                v-model="institutionForm.form.created_by"
                                                v-validate="'required|email'"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-checkbox
                                                :error-messages="errors.collect('institutionCheckbox', 'institutionForm')"
                                                :label="content.institutionForm.checkbox+'*'"
                                                color="success"
                                                data-vv-name="institutionCheckbox"
                                                required
                                                type="checkbox"
                                                v-model="institutionForm.form.checkbox"
                                                v-validate="'required'"
                                            ></v-checkbox>
                                        </v-flex>
                                        <v-flex xs12>
                                            <p class="red--text mb-0">* {{content.institutionForm.formRequired}}</p>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider class="my-3"></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="addInstitutionForm = false" color="blue darken-1" flat>
                                    {{content.institutionForm.close}}
                                </v-btn>
                                <v-btn :loading="institutionForm.submitIsLoading" @click="submitInstitutionForm"
                                       color="success" depressed>{{content.institutionForm.submit}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
                <v-layout row>
                    <v-flex xs12>
                        <v-alert
                            :value="institutionForm.isSuccess"
                            dismissible
                            outline
                            transition="scale-transition"
                            type="success"
                        >
                            <v-layout align-center justify-space-between row wrap>
                                <v-flex class="text-xs-left">
                                    {{content.institutionForm.alertSuccess1}}<br/>
                                    {{content.institutionForm.alertSuccess2}}
                                </v-flex>
                            </v-layout>
                        </v-alert>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-container>
        <v-snackbar
            color="error"
            multi-line
            top
            v-model="globalResponseError"
        >
            {{ globalResponseErrorMessage }}
            <v-btn
                @click="globalResponseError = false"
                dark flat icon
            >
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
        <v-snackbar
            :color="globalResendStatusColor"
            bottom
            multi-line
            v-model="globalResendStatus"
        >
            {{ globalResendStatusMessage }}
            <v-btn
                @click="globalResendStatus = false"
                dark flat icon
            >
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VeeValidate from 'vee-validate';
    import axios from 'axios';

    Vue.use(VeeValidate);

    export default {
        name: "HomepageForms",
        $_veeValidate: {
            validator: 'new'
        },
        props: ['translations', 'locale'],
        data: () => ({
            countries: [],
            signingForm: {
                valid: null,
                search: null,
                autocompleteIsLoading: false,
                autocompleteIsDisabled: true,
                autocompletePlaceholder: "",
                emailIsDisabled: true,
                emailFormats: {
                    type: Array
                },
                emailFormatsText: "",
                emailHint: "",
                submitIsLoading: false,
                resendIsLoading: false,
                isSuccess: false,
                isConfirmed: false,
                isPendingConfirmation: false,
                isPendingDeletion: false,
                isUnexpected: false,
                form: {
                    first_name: '',
                    last_name: '',
                    country: null,
                    institution: null,
                    institutions: [],
                    email: null,
                    checkbox: null,
                },
                id: null,
            },
            addInstitutionAlert: true,
            addInstitutionForm: false,
            institutionForm: {
                valid: null,
                email_format: null,
                form: {
                    country: null,
                    name: null,
                    email_formats: [],
                    created_by: null,
                    checkbox: null
                },
                institutionsName: [],
                nameValidationSpinner: false,
                nameIsDisabled: true,
                submitIsLoading: false,
                isSuccess: false
            },
            globalResponseError: false,
            globalResponseErrorMessage: null,
            globalResendStatus: false,
            globalResendStatusMessage: null,
            globalResendStatusColor: null,
            content: {signingForm: null, institutionForm: null, global: null},
            localeDict: null,
            dictionary: {
                en: {
                    content: {
                        signingForm: {
                            firstName: 'First Name',
                            lastName: 'Last Name',
                            country: "Institution's Country",
                            institution: "Institution's Name",
                            email: 'Email',
                            checkbox: "By signing, you agree that your name appears in the list of signatories next to your institution. You will receive an email to confirm your signature.",
                            submit: "Sign the Manifesto",
                            alertSuccess: "You successfully signed the Manifesto. You should receive a confirmation link via email in a few minutes.",
                            resendButton: "Resend Email",
                            alertConfirmed: "You already signed the Manifesto.",
                            alertPending: "You already signed the Manifesto. Please check your emails to get the confirmation link.",
                            alertSuppression: "You already signed the Manifesto but requested for your signature to be removed. Please check your emails to confirm the suppression or cancel it.",
                            alertError: "This signatory is already registered but something wrong happened. Please contact us about this issue.",
                            institutionSearch: "Search for an institution",
                            institutionSearchEmpty: "No institution from this country yet.",
                            confirmationResent: "The confirmation email was successfully resent.",
                            confirmationResendFailed: "The confirmation email was not resent due to an error. Please try again later.",
                            suppressionResent: "The suppression confirmation email was successfully resent.",
                            suppressionResendFailed: "The suppression confirmation email was not resent due to an error. Please try again later.",

                        },
                        institutionForm: {
                            alertAdd: "You cannot find your institution in the list?",
                            alertAddButton: "Add it",
                            formTitle: "Register your Institution",
                            country: "Institution's Country",
                            name: "Institution's Name",
                            domainsExplanation: "If your institution gives its students email addresses, please provide the addresses domain(s).",
                            domains: "Email Domain(s)",
                            domainsHint: "(Max: 5) Should respect this format: example.net or foo.example.net.",
                            domainsAddHint1: "Enter",
                            domainsAddHint2: "to add",
                            email: "Your Email Address",
                            emailHint: "It will be used to keep you updated when this institution is accepted or rejected. We will then automatically remove it from the database.",
                            checkbox: "By checking this box, you understand that your institution will not be visible until we accept it. You will be notified of our decision via email.",
                            formRequired: "Required field",
                            close: "Close",
                            submit: "Submit",
                            alertSuccess1: "You successfully registered your institution. You will receive confirmation via email in a few minutes.",
                            alertSuccess2: "We will keep you updated when it is accepted or not.",
                            emailDomainOr: "or",
                            emailDomainDefaultHint: "Type in your student email address.",
                            emailNoDomain: "No domains registered for this institution.",
                            registeredDomain: "Domain registered for this institution:",
                            requiredDomain: "Required email domain:",
                            registeredDomains: "Domains registered for this institution:",
                            requiredDomains: "Required email domains:",
                        },
                        global: {
                            responseErrorMessage: "An unexpected error happened. We're working on it.",
                            mountedError: "Something wrong happened. Check your Internet connection or try again later.",
                        }
                    },
                    errors: {
                        custom: {
                            // Signing form
                            signingFirstName: {
                                required: 'Cannot be empty.',
                                min: 'There must be at least 2 characters.',
                                max: 'There must not be more than 255 characters.'
                            },
                            signingLastName: {
                                required: 'Cannot be empty.',
                                min: 'There must be at least 2 characters.',
                                max: 'There must not be more than 255 characters.'
                            },
                            signingCountry: {
                                required: 'Cannot be empty.'
                            },
                            signingInstitution: {
                                required: "Cannot be empty."
                            },
                            signingEmail: {
                                required: 'Cannot be empty.',
                                email: 'Please enter a valid email address.',
                                existingFormat: 'An error happened. Try again later.'
                            },
                            signingCheckbox: {
                                required: 'You must agree before signing.'
                            },
                            // Institution form
                            institutionCountry: {
                                required: "Cannot be empty."
                            },
                            institutionName: {
                                doesntAlreadyExist: "The institution's name is already registered, it might be pending approval. Please try again later.",
                                required: "Cannot be empty.",
                                min: 'There must be at least 2 characters.',
                                max: 'There must not be more than 255 characters.',
                            },
                            institutionEmailFormats: {
                                regex: () => "At least one domain does not follow the normal pattern: example.net or foo.example.net."
                            },
                            institutionCreatedBy: {
                                required: 'Cannot be empty.',
                                email: 'Please enter a valid email address.',
                            },
                            institutionCheckbox: {
                                required: 'You must agree before submitting.'
                            }
                        }
                    }
                },
                fr: {
                    content: {
                        signingForm: {
                            firstName: 'Prénom',
                            lastName: 'Nom',
                            country: "Pays de l'établissement",
                            institution: "Nom de l'établissement",
                            email: 'Email',
                            checkbox: "En signant, vous acceptez que votre nom apparaisse dans la liste des signataires à côté de votre établissement. Vous recevrez un email pour confirmer votre signature.",
                            submit: "Signer le Manifeste",
                            alertSuccess: "Votre signature a été enregistrée. Vous devriez recevoir un lien de confirmation par email dans quelques minutes.",
                            resendButton: "Renvoyer l'email",
                            alertConfirmed: "Vous avez déjà signé et confirmé.",
                            alertPending: "Vous avez déjà signé le Manifeste. Vous devriez avoir reçu un lien de confirmation par email.",
                            alertSuppression: "Vous avez déjà signé le Manifeste mais avez demandé la suppression de votre signature. Vous devriez avoir reçu deux liens par email pour confirmer ou annuler.",
                            alertError: "Cette adresse email est déjà enregistrée mais un problème est survenu. Merci de nous contacter à propos de ce problème.",
                            institutionSearch: "Rechercher un établissement",
                            institutionSearchEmpty: "Pas d'établissement de ce pays pour le moment.",
                            confirmationResent: "L'email de confirmation a été renvoyé.",
                            confirmationResendFailed: "L'email de confirmation n'a pas été renvoyé à cause d'une erreur. Merci de réessayer plus tard.",
                            suppressionResent: "L'email de suppression a été renvoyé.",
                            suppressionResendFailed: "L'email de suppression n'a pas été renvoyé à cause d'une erreur. Merci de réessayer plus tard."
                        },
                        institutionForm: {
                            alertAdd: "Vous ne trouvez pas votre établissement dans la liste ?",
                            alertAddButton: "l'Ajouter",
                            formTitle: "Enregistrement de votre établissement",
                            country: "Pays de l'établissement",
                            name: "Nom de l'établissement",
                            domainsExplanation: "Si votre établissement fourni des adresses email à ses étudiants, merci d'indiquer le(s) domaine(s) de ces adresses (la partie après l'@).",
                            domains: "Domaine(s) d'Email",
                            domainsHint: "(Max: 5) Doit respecter ce format: exemple.net ou toto.exemple.net.",
                            domainsAddHint1: "Entrée",
                            domainsAddHint2: "pour ajouter",
                            email: "Votre Adresse Email",
                            emailHint: "Elle sera utilisée uniquement pour vous tenir à jour lorsque l'établissement est accepté ou rejeté. Elle sera ensuite immédiatement supprimée de notre base de données.",
                            checkbox: "En cochant cette case, vous comprenez que votre établissement ne sera visible qu'après validation de notre part. Vous serez notifié·e de notre décision par email.",
                            formRequired: "Champ obligatoire",
                            close: "Fermer",
                            submit: "Valider",
                            alertSuccess1: "Votre établissement a été enregistré. Vous recevrez un email récapitulatif dans quelques minutes.",
                            alertSuccess2: "Nous vous tiendrons au courant de la validation ou du rejet de votre contribution.",
                            emailDomainOr: "ou",
                            emailDomainDefaultHint: "Tapez votre adresse email d'étudiant·e.",
                            emailNoDomain: "Pas de domaine enregistré pour cet établissement.",
                            registeredDomain: "Domaine enregistré pour cet établissement :",
                            requiredDomain: "Domaine requis :",
                            registeredDomains: "Domaines enregistrés pour cet établissement :",
                            requiredDomains: "Domaines requis :"
                        },
                        global: {
                            responseErrorMessage: "Une erreur inattendue est survenue. Nous travaillons à régler le problème.",
                            mountedError: "Un problème est survenu. Vérifiez votre connexion à Internet ou réessayez plus tard."
                        }
                    },
                    errors: {
                        custom: {
                            // Signing form
                            signingFirstName: {
                                required: 'Ne peut pas être vide.',
                                min: 'Doit avoir au moins 2 caractères.',
                                max: 'Ne doit pas avoir plus de 255 caractères.'
                            },
                            signingLastName: {
                                required: 'Ne peut pas être vide.',
                                min: 'Doit avoir au moins 2 caractères.',
                                max: 'Ne doit pas avoir plus de 255 caractères.'
                            },
                            signingCountry: {
                                required: 'Ne peut pas être vide.'
                            },
                            signingInstitution: {
                                required: "Ne peut pas être vide"
                            },
                            signingEmail: {
                                required: 'Ne peut pas être vide.',
                                email: 'Doit être une adresse valide.',
                                existingFormat: 'Une erreur est survenue. Essayer plus tard.'
                            },
                            signingCheckbox: {
                                required: 'Vous devez accepter avant de signer.'
                            },
                            // Institution form
                            institutionCountry: {
                                required: "Ne peut pas être vide."
                            },
                            institutionName: {
                                doesntAlreadyExist: "Cet établissement est déjà enregistré, il peut être en attente de validation. Merci de réessayer plus tard.",
                                required: "Ne peut pas être vide.",
                                min: 'Doit avoir au moins 2 caractères.',
                                max: 'Ne doit pas avoir plus de 255 caractères.',
                            },
                            institutionEmailFormats: {
                                regex: () => "Au moins un domaine ne suit pas le bon format: example.net ou foo.example.net."
                            },
                            institutionCreatedBy: {
                                required: 'Ne peut pas être vide.',
                                email: 'Doit être une adresse valide.',
                            },
                            institutionCheckbox: {
                                required: "Vous devez accepter avant de valider."
                            }
                        }
                    }
                },
                es: {
                    content: {
                        signingForm: {
                            firstName: 'Nombre',
                            lastName: 'Apellidos',
                            country: "País",
                            institution: "Nombre de tu universidad",
                            email: 'Email',
                            checkbox: "Al firmar, estás de acuerdo con que tu nombre aparezca en la lista de nuestros firmantes junto con el nombre de tu universidad. Recibirás un email para confirmar tu firma.",
                            submit: "Firma el Manifiesto",
                            alertSuccess: "Has firmado el Manifiesto. Recibirás un email de confirmación en los próximos minutos.",
                            resendButton: "Reenviar email",
                            alertConfirmed: "Tú ya has firmado el Manifiesto.",
                            alertPending: "Ya has firmado el Manifiesto. Por favor, revisa tus emails para encontrar el link de confirmación.",
                            alertSuppression: "Ya has firmado el Manifiesto pero has solicitado que tu firma sea eliminada. Por favor, revisa tus emails para confirmar la eliminación o cancelación.",
                            alertError: "Esta firma ya esta registrada pero ha ocurrido un error. Por favor, ponte en contacto con nosotros y cuéntanos qué ha pasado.",
                            institutionSearch: "Busca una universidad",
                            institutionSearchEmpty: "No se ha registrado ninguna universidad de este país todavía.",
                            confirmationResent: "El email de confirmación se ha reenviado.",
                            confirmationResendFailed: "El email de confirmación no se ha enviado debido a un error. Por favor, inténtalo de nuevo más tarde.",
                            suppressionResent: "El email para confirmar la eliminación de tu firma se ha reenviado.",
                            suppressionResendFailed: "El email para confirmar la eliminación de tu firma no se ha enviado debido a un error. Por favor, inténtalo de nuevo más tarde.",

                        },
                        institutionForm: {
                            alertAdd: "¿No puedes encontrar tu universidad en la lista?",
                            alertAddButton: "Añádela",
                            formTitle: "Registra tu universidad",
                            country: "País",
                            name: "Nombre de tu universidad",
                            domainsExplanation: "Por favor, introduce el dominio de la dirección email de los estudiantes (lo que va después del @)",
                            domains: "Dominio(s)",
                            domainsHint: "Por favor, respeta este formato: uu.es o estudiantes.uu.es (Máx. 5 dominios)",
                            domainsAddHint1: "Intro",
                            domainsAddHint2: "para añadir",
                            email: "Tu dirección email",
                            emailHint: "Será usada para informarte de si esta universidad es aceptada o rechazada. A partir de ese momento será eliminada de nuestra base de datos.",
                            checkbox: "Marcando esta casilla, aceptas que tu universidad no sea visible hasta que no la aceptemos. Pronto te enviaremos un email con nuestra decisión.",
                            formRequired: "Campo requerido",
                            close: "Cerrar",
                            submit: "Enviar",
                            alertSuccess1: "Has registrado tu universidad con éxito. Recibirás un email de confirmación en unos minutos.",
                            alertSuccess2: "Pronto te haremos saber si es aceptada o no.",
                            emailDomainOr: "o",
                            emailDomainDefaultHint: "Escribe tu dirección email estudiante.",
                            emailNoDomain: "No hay un dominio registrado para esta universidad.",
                            registeredDomain: "Dominio registrado para esta universidad:",
                            requiredDomain: "Dominio email requerido:",
                            registeredDomains: "Dominios registrados para esta universidad:",
                            requiredDomains: "Dominios email requeridos",
                        },
                        global: {
                            responseErrorMessage: "Ocurrió un error inesperado. Estamos trabajando en ello.",
                            mountedError: "Ha pasado algo malo. Compruebe su conexión a Internet o inténtelo de nuevo más tarde.",
                        }
                    },
                    errors: {
                        custom: {
                            // Signing form
                            signingFirstName: {
                                required: 'No puede estar vacío.',
                                min: 'Son necesarios al menos 2 caracteres.',
                                max: 'No puede sobrepasar los 255 caracteres.'
                            },
                            signingLastName: {
                                required: 'No puede estar vacío..',
                                min: 'Son necesarios al menos 2 caracteres.',
                                max: 'No puede sobrepasar los 255 caracteres.'
                            },
                            signingCountry: {
                                required: 'No puede estar vacío..'
                            },
                            signingInstitution: {
                                required: "No puede estar vacío.."
                            },
                            signingEmail: {
                                required: 'No puede estar vacío..',
                                email: 'Por favor, introduce una dirección email válida.',
                                existingFormat: 'Ha ocurrido un error. Intentalo de nuevo más tarde.'
                            },
                            signingCheckbox: {
                                required: 'Aceptar antes de firmar.'
                            },
                            // Institution form
                            institutionCountry: {
                                required: "No puede estar vacío."
                            },
                            institutionName: {
                                doesntAlreadyExist: "El nombre de tu universidad ya está registrado, puede estar pendiente de aprobación. Por favor, inténtalo de nuevo más tarde.",
                                required: "No puede estar vacío..",
                                min: 'Son necesarios al menos 2 caracteres.',
                                max: 'No puede sobrepasar los 255 caracteres.',
                            },
                            institutionEmailFormats: {
                                regex: () => "Al menos un dominio no sigue el patrón: uu.es o estudiantes.uu.es"
                            },
                            institutionCreatedBy: {
                                required: 'No puede estar vacío..',
                                email: 'Por favor, introduce una dirección email válida.',
                            },
                            institutionCheckbox: {
                                required: 'Debes aceptar antes de enviarlo.'
                            }
                        }
                    }
                },
                it: {
                    content: {
                        signingForm: {
                            firstName: 'Nome',
                            lastName: 'Cognome',
                            country: "Paese dell'istituzione",
                            institution: "Nome dell'istituzione",
                            email: 'Email',
                            checkbox: "Firmando, accetti che il tuo nome appaia nella lista dei firmatari accanto alla tua istituzione. Riceverai un'e-mail di conferma della tua firma.",
                            submit: "Firma il Manifesto",
                            alertSuccess: "Hai firmato con successo il Manifesto. Dovresti ricevere un link di conferma via e-mail in pochi minuti.",
                            resendButton: "Reinvia e-mail",
                            alertConfirmed: "Hai gia' firmato il Manifesto.",
                            alertPending: "Hai gia' firmato il Manifesto. Controlla le tue email per ottenere il link di conferma.",
                            alertSuppression: "Hai già firmato il Manifesto ma hai chiesto che la tua firma venga rimossa. Controlla le tue email per confermare la soppressione o annullarla.",
                            alertError: "Questo firmatario è già registrato, ma c'è un errore. Ti preghiamo di contattarci per questo.",
                            institutionSearch: "Ricerca un'istituzione",
                            institutionSearchEmpty: "Nessuna istituzione in questo paese al momento.",
                            confirmationResent: "L'e-mail di conferma è stata inviata con successo.",
                            confirmationResendFailed: "L'e-mail di conferma non è stata rispedita a causa di un errore. Per favore, riprova più tardi.",
                            suppressionResent: "L'e-mail di conferma della soppressione è stata inviata con successo.",
                            suppressionResendFailed: "L'e-mail di conferma dell'eliminazione non è stata rispedita a causa di un errore. Per favore, riprova più tardi.",

                        },
                        institutionForm: {
                            alertAdd: "Non trovi il nome della tua università sulla lista?",
                            alertAddButton: "Aggiungila",
                            formTitle: "Registra la tua istituzione",
                            country: "Paese dell'istituzione",
                            name: "Nome dell'istituzione",
                            domainsExplanation: "Se la vostra istituzione fornisce ai suoi studenti indirizzi e-mail, si prega di fornire gli indirizzi di dominio.",
                            domains: "Dominio(i) e-mail",
                            domainsHint: "(Max: 5) Deve rispettare questo formato: esempio.net o foo.esempio.net.",
                            domainsAddHint1: "Entra",
                            domainsAddHint2: "per aggiungere",
                            email: "Il tuo indirizzo e-mail",
                            emailHint: "Sarà utilizzato per tenerti aggiornati quando questa istituzione sarà accettata o respinta. Lo rimuoveremo automaticamente dal database.",
                            checkbox: "Selezionando questa casella, si capisce che la tua istituzione non sarà visibile finché non lo accettiamo. La nostra decisione vi sarà comunicata via e-mail.",
                            formRequired: "Obbligatorio",
                            close: "Chiudere",
                            submit: "Invia",
                            alertSuccess1: "Hai registrato con successo il tuo istituto. Riceverai la conferma via e-mail in pochi minuti.",
                            alertSuccess2: "Ti terremo aggiornato quando sarà accettato o meno.",
                            emailDomainOr: "o",
                            emailDomainDefaultHint: "Inserisci il tuo indirizzo e-mail (quello fornito dalla tua università).",
                            emailNoDomain: "Nessun dominio registrato per questa istituzione.",
                            registeredDomain: "Dominio registrato per questa istituzione:",
                            requiredDomain: "Dominio e-mail richiesto:",
                            registeredDomains: "Domini registrati per questa istituzione:",
                            requiredDomains: "Domini e-mail richiesti:",
                        },
                        global: {
                            responseErrorMessage: "Si è verificato un errore inaspettato. Ci stiamo lavorando.",
                            mountedError: "E' successo qualcosa di sbagliato. Controllate la vostra connessione a Internet o riprovate più tardi.",
                        }
                    },
                    errors: {
                        custom: {
                            // Signing form
                            signingFirstName: {
                                required: 'Non può essere vuoto.',
                                min: 'Ci devono essere almeno 2 caratteri.',
                                max: 'Non devono esserci più di 255 caratteri.'
                            },
                            signingLastName: {
                                required: 'Non può essere vuoto.',
                                min: 'Ci devono essere almeno 2 caratteri.',
                                max: 'Non devono esserci più di 255 caratteri.'
                            },
                            signingCountry: {
                                required: 'Non può essere vuoto.'
                            },
                            signingInstitution: {
                                required: "Non può essere vuoto.."
                            },
                            signingEmail: {
                                required: 'Non può essere vuoto.',
                                email: 'Inserisci un indirizzo e-mail valido, per favore.',
                                existingFormat: 'Si è verificato un errore. Riprova più tardi.'
                            },
                            signingCheckbox: {
                                required: 'Devi dare il tuo consenso prima di firmare.'
                            },
                            // Institution form
                            institutionCountry: {
                                required: "Non può essere vuoto."
                            },
                            institutionName: {
                                doesntAlreadyExist: "Il nome dell'istituzione è già registrato, potrebbe essere in attesa di approvazione.  Per favore, riprova più tardi.",
                                required: "Non può essere vuoto.",
                                min: 'Ci devono essere almeno 2 caratteri.',
                                max: 'Non devono esserci più di 255 caratteri.',
                            },
                            institutionEmailFormats: {
                                regex: () => "Almeno un dominio non segue lo schema normale: esempio.net o foo.esempio.net."
                            },
                            institutionCreatedBy: {
                                required: 'Non può essere vuoto.',
                                email: 'Inserisci un indirizzo e-mail valido, per favore.',
                            },
                            institutionCheckbox: {
                                required: 'Devi dare il tuo consenso prima di inviare la tua richiesta.'
                            }
                        }
                    }
                }
            }
        }),
        created() {
            switch (this.locale) {
                case 'fr':
                    this.content = this.dictionary.fr.content;
                    this.localeDict = this.dictionary.fr;
                    break;
                case 'es':
                    this.content = this.dictionary.es.content;
                    this.localeDict = this.dictionary.es;
                    break;
                case 'it':
                    this.content = this.dictionary.it.content;
                    this.localeDict = this.dictionary.it;
                    break;
                default:
                    this.content = this.dictionary.en.content;
                    this.localeDict = this.dictionary.en;
                    break;
            }
            this.$validator.localize(this.locale, this.localeDict.errors);
        },
        mounted() {
            axios.get('/api/countries', {
                params: {
                    locale: this.locale
                }
            })
                .then(({data}) => this.countries = data)
                .catch((error) => {
                    console.log(error);
                    this.globalResponseErrorMessage = this.content.global.mountedError;
                    this.globalResponseError = true;
                });
        },
        watch: {
            search(val) {
            },
            'institutionForm.form.email_formats': function (val) {
                if (val.length > 5) {
                    this.$nextTick(() => this.institutionForm.form.email_formats.pop())
                }
            }
        },
        methods: {
            resendConfirmationEmail() {
                this.signingForm.resendIsLoading = true;
                axios.post('/api/signatories/' + this.signingForm.id + '/resend-confirmation', {
                    locale: this.locale
                })
                    .then(() => {
                        this.globalResendStatusMessage = this.content.signingForm.confirmationResent;
                        this.globalResendStatusColor = "success";
                        this.globalResendStatus = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.globalResendStatusMessage = this.content.institutionForm.confirmationResendFailed;
                        this.globalResendStatusColor = "error";
                        this.globalResendStatus = true;
                    })
                    .finally(() => {
                        this.signingForm.resendIsLoading = false;
                    })
            },
            resendSuppressionEmail() {
                this.signingForm.resendIsLoading = true;
                axios.post('/api/signatories/' + this.signingForm.id + '/resend-suppression', {
                    locale: this.locale,
                })
                    .then(() => {
                        this.globalResendStatusMessage = this.content.signingForm.suppressionResent;
                        this.globalResendStatusColor = "success";
                        this.globalResendStatus = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.globalResendStatusMessage = this.content.signingForm.suppressionResendFailed;
                        this.globalResendStatusColor = "error";
                        this.globalResendStatus = true;
                    })
                    .finally(() => {
                        this.signingForm.resendIsLoading = false;
                    })
            },
            resetSigningForm: function () {
                this.signingForm.isSuccess = true;
                this.signingForm.form.first_name = "";
                this.signingForm.form.last_name = "";
                this.signingForm.form.country = null;
                this.signingForm.form.institution = null;
                this.signingForm.form.institutions = [];
                this.signingForm.search = null;
                this.signingForm.autocompleteIsDisabled = true;
                this.signingForm.autocompletePlaceholder = "";
                this.signingForm.autocompleteIsLoading = false;
                this.signingForm.form.email = null;
                this.signingForm.emailIsDisabled = true;
                this.signingForm.emailHint = "";
                this.signingForm.form.checkbox = null;
                this.$validator.reset({scope: 'signingForm'});
            },
            resetInstitutionForm: function () {
                this.institutionForm.isSuccess = true;
                this.institutionForm.form.country = null;
                this.institutionForm.form.name = null;
                this.institutionForm.form.email_formats = [];
                this.institutionForm.form.created_by = null;
                this.institutionForm.form.checkbox = null;
                this.institutionForm.email_format = null;
                this.institutionForm.institutionsName = [];
                this.institutionForm.nameValidationSpinner = false;
                this.institutionForm.nameIsDisabled = true;
                this.$validator.reset({scope: 'institutionForm'});
            },
            setEmailFormat() {
                this.signingForm.emailFormats = this.signingForm.form.institution.email_formats;
                this.signingForm.emailFormatsText = "";
                const length = this.signingForm.emailFormats.length;
                for (let i = 0; i < length; i++) {
                    if (i < length - 2) {
                        this.signingForm.emailFormatsText += "@" + this.signingForm.emailFormats[i] + ", ";
                    } else if (i < length - 1) {
                        this.signingForm.emailFormatsText += "@" + this.signingForm.emailFormats[i] + " " + this.content.institutionForm.emailDomainOr + " ";
                    } else {
                        this.signingForm.emailFormatsText += "@" + this.signingForm.emailFormats[i] + ".";
                    }
                }
                if (this.signingForm.emailFormats.length === 0) {
                    this.signingForm.emailHint = this.content.institutionForm.emailDomainDefaultHint;
                    this.localeDict.errors.custom.signingEmail.existingFormat = this.content.institutionForm.emailNoDomain;
                } else if (this.signingForm.emailFormats.length === 1) {
                    this.signingForm.emailHint = this.content.institutionForm.registeredDomain + " " + this.signingForm.emailFormatsText;
                    this.localeDict.errors.custom.signingEmail.existingFormat = this.content.institutionForm.requiredDomain + " " + this.signingForm.emailFormatsText;
                } else {
                    this.signingForm.emailHint = this.content.institutionForm.registeredDomains + " " + this.signingForm.emailFormatsText;
                    this.localeDict.errors.custom.signingEmail.existingFormat = this.content.institutionForm.requiredDomains + " " + this.signingForm.emailFormatsText;
                }
                // Creates rule to validate
                const usesEmailFormat = (value) => {
                    const domain = value.split('@')[1];
                    if (this.signingForm.emailFormats.length === 0) {
                        return true
                    } else {
                        return this.signingForm.emailFormats.includes(domain);
                    }
                };
                this.$validator.extend('existingFormat', usesEmailFormat);
                // Reloading the error bag
                switch (this.locale) {
                    case 'fr':
                        this.$validator.localize('fr', this.dictionary.fr.errors);
                        break;
                    case 'es':
                        this.$validator.localize('es', this.dictionary.es.errors);
                        break;
                    case 'it':
                        this.$validator.localize('it', this.dictionary.it.errors);
                        break;
                    default:
                        this.$validator.localize('en', this.dictionary.en.errors);
                        break;
                }
                this.signingForm.emailIsDisabled = false;
            },
            loadInstitutions() {
                // Items have already been requested
                if (this.signingForm.autocompleteIsLoading) return;

                this.signingForm.autocompleteIsLoading = true;

                axios.get('/api/institutions', {
                    params: {
                        country_id: this.signingForm.form.country
                    }
                })
                    .then(({data}) => this.signingForm.form.institutions = data)
                    .catch((error) => {
                        if (error.response.data.message === "") {
                            this.globalResponseErrorMessage = this.content.global.responseErrorMessage;
                        } else {
                            this.globalResponseErrorMessage = error.response.data.message;
                        }
                        this.globalResponseError = true;
                    })
                    .finally(() => {
                        if (this.signingForm.form.institutions.length > 0) {
                            this.signingForm.autocompleteIsDisabled = false;
                            this.signingForm.autocompletePlaceholder = this.content.signingForm.institutionSearch;
                        } else {
                            this.signingForm.autocompleteIsDisabled = true;
                            this.signingForm.emailIsDisabled = true;
                            this.signingForm.form.email = "";
                            this.signingForm.emailHint = "";
                            this.signingForm.autocompletePlaceholder = this.content.signingForm.institutionSearchEmpty;
                        }
                        this.signingForm.autocompleteIsLoading = false;
                    });
            },
            loadInstitutionsName() {
                this.institutionForm.nameValidationSpinner = true;
                axios.get('/api/institutions', {
                    params: {
                        country_id: this.institutionForm.form.country,
                        limit_to: "name"
                    }
                })
                    .then(({data}) => this.institutionForm.institutionsName = data)
                    .catch((error) => {
                        if (error.response.data.message === "") {
                            this.globalResponseErrorMessage = this.content.global.responseErrorMessage;
                        } else {
                            this.globalResponseErrorMessage = error.response.data.message;
                        }
                        this.globalResponseError = true;
                    })
                    .finally(() => {
                        this.institutionForm.nameIsDisabled = false;
                        this.institutionForm.nameValidationSpinner = false;
                    });

                // Create doesntAlreadyExist rule
                const nameDoesntAlreadyExist = (value) => {
                    return !this.institutionForm.institutionsName.includes(value);
                };
                this.$validator.extend('doesntAlreadyExist', nameDoesntAlreadyExist);
            },
            submitSigningForm() {
                this.$validator.validateAll('signingForm')
                    .then((result => {
                        if (result) {
                            this.signingForm.submitIsLoading = true;
                            axios.post('/fr/signatories', {
                                locale: this.locale,
                                first_name: this.signingForm.form.first_name,
                                last_name: this.signingForm.form.last_name,
                                institution_id: this.signingForm.form.institution.id,
                                email: this.signingForm.form.email,
                                checkbox: this.signingForm.form.checkbox
                            })
                                .then((response) => {
                                    this.signingForm.id = response.data.id;
                                    this.resetSigningForm();
                                })
                                .catch((error) => {
                                    this.signingForm.id = error.response.data.id;
                                    if (error.response.data.message === "") {
                                        this.globalResponseErrorMessage = this.content.global.responseErrorMessage;
                                    } else {
                                        this.globalResponseErrorMessage = error.response.data.message;
                                    }
                                    this.globalResponseError = true;
                                    if (error.response.data.errors) {
                                        if (error.response.data.errors.first_name) {
                                            this.$validator.errors.add({
                                                field: 'signingFirstName',
                                                msg: error.response.data.errors.first_name,
                                                scope: 'signingForm'
                                            });
                                        }
                                        if (error.response.data.errors.last_name) {
                                            this.$validator.errors.add({
                                                field: 'signingLastName',
                                                msg: error.response.data.errors.last_name,
                                                scope: 'signingForm'
                                            });
                                        }
                                        if (error.response.data.errors.institution_id) {
                                            this.$validator.errors.add({
                                                field: 'signingInstitution',
                                                msg: error.response.data.errors.institution_id,
                                                scope: 'signingForm'
                                            });
                                        }
                                        if (error.response.data.errors.email) {
                                            this.$validator.errors.add({
                                                field: 'signingEmail',
                                                msg: error.response.data.errors.email,
                                                scope: 'signingForm'
                                            });
                                        }
                                        if (error.response.data.errors.checkbox) {
                                            this.$validator.errors.add({
                                                field: 'signingCheckbox',
                                                msg: error.response.data.errors.checkbox,
                                                scope: 'signingForm'
                                            });
                                        }
                                        if (error.response.data.reason) {
                                            if (error.response.data.reason === "confirmed") {
                                                this.signingForm.isConfirmed = true;
                                            } else if (error.response.data.reason === "pendingConfirmation") {
                                                this.signingForm.isPendingConfirmation = true;
                                            }
                                            if (error.response.data.reason === "pendingDeletion") {
                                                this.signingForm.isPendingDeletion = true;
                                            }
                                            if (error.response.data.reason === 'exists') {
                                                this.signingForm.isUnexpected = true;
                                            }
                                        }
                                    }
                                })
                                .finally(() => {
                                    this.signingForm.submitIsLoading = false;
                                })
                        }
                    }))
                    .catch(err => {
                        alert(err);
                    })
            },
            submitInstitutionForm() {
                this.$validator.validateAll('institutionForm')
                    .then((result => {
                        if (result) {
                            this.institutionForm.submitIsLoading = true;
                            axios.post('/fr/institutions', {
                                locale: this.locale,
                                country_id: this.institutionForm.form.country,
                                name: this.institutionForm.form.name,
                                email_formats: this.institutionForm.form.email_formats,
                                created_by: this.institutionForm.form.created_by,
                                checkbox: this.institutionForm.form.checkbox
                            })
                                .then(() => {
                                    this.resetInstitutionForm();
                                    this.addInstitutionForm = false;
                                    this.addInstitutionAlert = false;
                                })
                                .catch((error) => {
                                    if (error.response.data.message === "") {
                                        this.globalResponseErrorMessage = this.content.global.responseErrorMessage;
                                    } else {
                                        this.globalResponseErrorMessage = error.response.data.message;
                                    }
                                    this.globalResponseError = true;
                                    if (error.response.data.errors) {
                                        if (error.response.data.errors.country_id) {
                                            this.$validator.errors.add({
                                                field: 'institutionCountry',
                                                msg: error.response.data.errors.country_id,
                                                scope: 'institutionForm'
                                            });
                                        }
                                        if (error.response.data.errors.name) {
                                            this.$validator.errors.add({
                                                field: 'institutionName',
                                                msg: error.response.data.errors.name,
                                                scope: 'institutionForm'
                                            });
                                        }
                                        if (error.response.data.errors.email_formats) {
                                            this.$validator.errors.add({
                                                field: 'institutionEmailFormats',
                                                msg: error.response.data.errors.email_formats,
                                                scope: 'institutionForm'
                                            });
                                        }
                                        if (error.response.data.errors.created_by) {
                                            this.$validator.errors.add({
                                                field: 'institutionCreatedBy',
                                                msg: error.response.data.errors.created_by,
                                                scope: 'institutionForm'
                                            });
                                        }
                                        if (error.response.data.errors.checkbox) {
                                            this.$validator.errors.add({
                                                field: 'institutionCheckbox',
                                                msg: error.response.data.errors.checkbox,
                                                scope: 'institutionForm'
                                            });
                                        }
                                    }
                                })
                                .finally(() => {
                                    this.institutionForm.submitIsLoading = false;
                                })
                        }
                    }))
                    .catch(err => {
                        alert(err);
                    })
            }
        }
    }
</script>
