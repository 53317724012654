<template>
    <form :action="route" method="post">
        <slot></slot>
        <v-card class="mb-3 px-3 pb-1">
            <v-card-title class="mb-0 pb-0" primary-title>
                <div>
                    <h5 :class="'headline mb-0 pb-0 '+color+'--text'" v-text="title"></h5>
                </div>
            </v-card-title>
            <v-divider class="mt-3"></v-divider>
            <v-card-text>
                <div v-validate="valid">
                    <v-container>
                        <v-layout>
                            <v-flex xs12>
                                <v-text-field
                                    :error-messages="errors.collect('confirmation_code')"
                                    :hint="content.confirmationCodeHint"
                                    :label="content.confirmationCode"
                                    @blur="validate"
                                    @change="validate"
                                    @click="validate"
                                    @error="validate"
                                    @focus="validate"
                                    @input="validate"
                                    @invalid="validate"
                                    @load="validate"
                                    data-vv-name="confirmation_code"
                                    name="confirmation_code"
                                    persistent-hint
                                    required
                                    v-model="confirmation_code"
                                    v-validate="'required|length:64'"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="color" :disabled="!canSubmit" flat type="submit">{{content.submit}}</v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import Vue from 'vue';
    import VeeValidate from 'vee-validate';

    Vue.use(VeeValidate);
    export default {
        $_veeValidate: {
            validator: 'new'
        },
        name: "ConfirmationFormComponent",
        props: ['confirmation_code', 'id', 'title', 'route', 'color', 'locale'],
        data: () => ({
            valid: null,
            canSubmit: false,
            content: null,
            dictionary: {
                en: {
                    content: {
                        confirmationCode: "Confirmation Code",
                        confirmationCodeHint: "Should be filled automatically if you followed the confirmation link.",
                        submit: "Confirm"
                    },
                    errors: {
                        custom: {
                            confirmation_code: {
                                required: () => 'Can not be empty.',
                                length: 'Must be 64 characters long.'
                            },
                        }
                    }
                },
                fr: {
                    content: {
                        confirmationCode: "Code de Confirmation",
                        confirmationCodeHint: "Devrait être automatiquement rempli si vous avez suivi le lien de confirmation.",
                        submit: "Confirmer"
                    },
                    errors: {
                        custom: {
                            confirmation_code: {
                                required: () => 'Ne peut pas être vide.',
                                length: 'Doit contenir 64 caractères.'
                            },
                        }
                    }
                },
                es: {
                    content: {
                        confirmationCode: "Código de confirmación",
                        confirmationCodeHint: "Se completa de manera automática si has seguido el link de confirmación.",
                        submit: "Confirmar"
                    },
                    errors: {
                        custom: {
                            confirmation_code: {
                                required: () => 'Este campo no puede estar vacío.',
                                length: 'Debe contener al menos 64 caracteres.'
                            },
                        }
                    }
                },
                it: {
                    content: {
                        confirmationCode: "Codice di conferma",
                        confirmationCodeHint: "Dovrebbe essere compilato automaticamente se hai seguito il link di conferma.",
                        submit: "Conferma"
                    },
                    errors: {
                        custom: {
                            confirmation_code: {
                                required: () => 'Non può essere vuoto.',
                                length: 'Deve essere lunga 64 caratteri.'
                            },
                        }
                    }
                }
            },
        }),
        created() {
            switch (this.locale) {
                case 'fr':
                    this.content = this.dictionary.fr.content;
                    this.$validator.localize('fr', this.dictionary.fr.errors);
                    break;
                case 'es':
                    this.content = this.dictionary.es.content;
                    this.$validator.localize('es', this.dictionary.es.errors);
                    break;
                case 'it':
                    this.content = this.dictionary.it.content;
                    this.$validator.localize('it', this.dictionary.it.errors);
                    break;
                default:
                    this.content = this.dictionary.en.content;
                    this.$validator.localize('en', this.dictionary.en.errors);
                    break;
            }
        },
        mounted() {
            this.validate();
        },
        methods: {
            validate() {
                this.$validator.validateAll()
                    .then((result => {
                        this.canSubmit = !!result;
                    }))
                    .catch((errors => {
                        this.canSubmit = errors;
                    }))
            }
        }
    }
</script>

<style scoped>

</style>
