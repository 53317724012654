<template>
    <v-card class="px-3 pb-3">
        <v-form
                data-vv-scope="terminate"
                v-model="valid"
        >
            <v-card-title primary-title>
                <v-layout wrap>
                    <v-flex xs12>
                        <h5 class="headline mb-0">Terminate</h5>
                    </v-flex>
                </v-layout>
            </v-card-title>
            <v-card-text>
                <v-layout wrap>
                    <v-flex md4 sm6 xs12>
                        <v-select
                            :error-messages="errors.collect('locale', 'terminate')"
                            :items="locales"
                            data-vv-name="locale"
                            item-text="name"
                            item-value="id"
                            label="Contributor's locale"
                            v-model="form.locale"
                            v-validate="'required'"
                        ></v-select>
                    </v-flex>
                    <v-flex class="hidden-xs-only" md8 sm6></v-flex>
                    <v-flex xs12>
                        <v-textarea :error-messages="errors.collect('comment', 'terminate')"
                                    counter="500"
                                    data-vv-name="comment"
                                    label="Comment"
                                    v-model="form.comment"
                                    v-validate="'required|min:2|max:500'"
                        ></v-textarea>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="rejectIsLoading" @click="reject" color="error">
                    <v-icon small>close</v-icon>&nbsp;Reject
                </v-btn>
                <v-btn :loading="acceptIsLoading" @click="accept" color="success">
                    <v-icon small>check</v-icon>&nbsp;Accept
                </v-btn>
            </v-card-actions>
        </v-form>
        <v-snackbar
                :color="globalResponseType"
                multi-line
                top
                v-model="globalResponse"
        >
            {{ globalResponseMessage }}
            <v-btn
                    @click="globalResponse = false"
                    dark
                    flat
            >
                Close
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
    import Vue from 'vue';
    import VeeValidate from 'vee-validate';

    Vue.use(VeeValidate);
    export default {
        name: "TerminatePendingInstitutionComponent",
        $_veeValidate: {
            validator: 'new'
        },
        props: ['id', 'confirmation_code', 'success_redirect', 'locale'],
        data() {
            return {
                locales: [],
                valid: null,
                form: {
                    locale: this.locale,
                    comment: null
                },
                rejectIsLoading: false,
                acceptIsLoading: false,
                globalResponse: false,
                globalResponseType: '',
                globalResponseMessage: null,
                dictionary: {
                    custom: {
                        locale: {
                            required: "Locale cannot be empty."
                        },
                        comment: {
                            required: () => "You have to add a comment.",
                            min: 'There must be at least 2 characters.',
                            max: 'There must not be more than 500 characters.',
                        }
                    }
                }
            }
        },
        mounted() {
            axios.get('/api/locales')
                .then(({data}) => this.locales = data)
                .catch((error) => {
                    this.globalResponseMessage = "Something wrong happened. Check your Internet connection or try again later.";
                    this.globalResponseType = 'error';
                    this.globalResponse = true;
                });
            this.$validator.localize('en', this.dictionary, 'terminate');
        },
        methods: {
            accept() {
                this.$validator.validateAll('terminate')
                    .then((result => {
                        if (result) {
                            this.acceptIsLoading = true;
                            axios.put('/admin/institutions/pending', {
                                locale: this.form.locale,
                                comment: this.form.comment,
                                id: this.id,
                                confirmation_code: this.confirmation_code,
                            })
                                .then((response) => {
                                    window.location = this.success_redirect;
                                })
                                .catch((error) => {
                                    if (error.response.data.message === "") {
                                        this.globalResponseMessage = "An unexpected error happened. We're working on it.";
                                    } else {
                                        this.globalResponseMessage = error.response.data.message;
                                    }
                                    this.globalResponseType = 'error';
                                    this.globalResponse = true;
                                    if (error.response.data.errors) {
                                        if (error.response.data.errors.comment) {
                                            this.$validator.errors.add({
                                                field: 'comment',
                                                msg: error.response.data.errors.comment,
                                                scope: 'terminate'
                                            });
                                        }
                                    }
                                })
                                .finally(() => {
                                    this.acceptIsLoading = false;
                                })
                        }
                    }))
                    .catch(err => {
                        alert(err);
                    })
            },
            reject() {
                this.$validator.validateAll('terminate')
                    .then((result => {
                        if (result) {
                            this.rejectIsLoading = true;
                            axios.put('/admin/institutions/pending/' + this.id + '/delete', {
                                locale: this.form.locale,
                                comment: this.form.comment,
                                id: this.id,
                                confirmation_code: this.confirmation_code,
                            })
                                .then((response) => {
                                    window.location = this.success_redirect;
                                })
                                .catch((error) => {
                                    if (error.response.data.message === "") {
                                        this.globalResponseMessage = "An unexpected error happened. We're working on it.";
                                    } else {
                                        this.globalResponseMessage = error.response.data.message;
                                    }
                                    this.globalResponseType = 'error';
                                    this.globalResponse = true;
                                    if (error.response.data.errors) {
                                        if (error.response.data.errors.comment) {
                                            this.$validator.errors.add({
                                                field: 'comment',
                                                msg: error.response.data.errors.comment,
                                                scope: 'terminate'
                                            });
                                        }
                                    }
                                })
                                .finally(() => {
                                    this.rejectIsLoading = false;
                                })
                        }
                    }))
                    .catch(err => {
                        alert(err);
                    })
            }
        }
    }
</script>

<style scoped>

</style>
