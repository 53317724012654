<template>
    <v-card class="px-3 pb-3">
        <v-form
                data-vv-scope="edit"
                lazy-validation
                v-model="valid"
        >
            <v-card-title primary-title>
                <v-layout wrap>
                    <v-flex xs12>
                        <h5 class="headline mb-0">Update {{ form.name }}</h5>
                    </v-flex>
                    <v-flex xs12>
                        <h6 class="subheading">
                            <a :href="'mailto:'+created_by+'?subject=[Manifesto] Update about your submission'"
                               class="grey--text"
                               target="_blank"
                            >{{ created_by }}</a>
                        </h6>
                    </v-flex>
                </v-layout>
            </v-card-title>
            <v-card-text class="py-0">
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex md4 sm6 xs12>
                            <v-select
                                    :error-messages="errors.collect('country', 'edit')"
                                    :items="countries"
                                    data-vv-name="country"
                                    item-text="name"
                                    item-value="id"
                                    label="Institution's Country"
                                    v-model="form.country"
                                    v-validate="'required'"
                            ></v-select>
                        </v-flex>
                        <v-flex md8 sm6 xs12>
                            <v-text-field
                                    :error-messages="errors.collect('name', 'edit')"
                                    data-vv-name="name"
                                    label="Institution's Name"
                                    v-model="form.name"
                                    v-validate="'min:2|max:255'"
                            ></v-text-field>
                        </v-flex>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-combobox
                                        :error-messages="errors.collect('emailFormats', 'edit')"
                                        :search-input.sync="email_format"
                                        class="pt-0"
                                        data-vv-name="emailFormats"
                                        hide-selected
                                        hint="(Max: 5) Should respect this format: example.net or foo.example.net."
                                        label="Email Domain(s)"
                                        multiple
                                        persistent-hint
                                        small-chips
                                        v-model="form.email_formats"
                                        v-validate="{min: 5, max: 250, regex: '^([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$' }"
                                >
                                    <template slot="no-data">
                                        <v-list-tile>
                                            <v-list-tile-content>
                                                <v-list-tile-title>
                                                    Press <kbd>Enter</kbd> to add "<strong>{{ email_format }}</strong>".
                                                </v-list-tile-title>
                                            </v-list-tile-content>
                                        </v-list-tile>
                                    </template>
                                </v-combobox>
                            </v-flex>
                        </v-layout>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-divider class="my-3"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="submitIsLoading" @click="update" color="success">
                    Update
                </v-btn>
            </v-card-actions>
        </v-form>
        <v-snackbar
                :color="globalResponseType"
                multi-line
                top
                v-model="globalResponse"
        >
            {{ globalResponseMessage }}
            <v-btn
                    @click="globalResponse = false"
                    dark
                    flat
            >
                Close
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
    import Vue from 'vue';
    import VeeValidate from 'vee-validate';

    Vue.use(VeeValidate);
    export default {
        name: "EditPendingInstitutionComponent",
        $_veeValidate: {
            validator: 'new'
        },
        props: ['created_by', 'id', 'confirmation_code'],
        data() {
            return {
                countries: [],
                valid: null,
                email_format: null,
                form: {
                    country: null,
                    name: null,
                    email_formats: [],
                },
                submitIsLoading: false,
                globalResponse: false,
                globalResponseType: '',
                globalResponseMessage: null,
                dictionary: {
                    custom: {
                        country: {
                            required: "Country cannot be empty."
                        },
                        name: {
                            doesntAlreadyExist: "The institution's name is already registered, it might be pending approval. Please try again later.",
                            required: () => "Institution's name cannot be empty.",
                            min: 'There must be at least 2 characters.',
                            max: 'There must not be more than 255 characters.',
                        },
                        emailFormats: {
                            regex: () => "At least one domain does not follow the normal pattern: example.net or foo.example.net."
                        }
                    }
                }
            }
        },
        mounted() {
            this.$validator.localize('en', this.dictionary, 'edit');
            axios.get('/api/countries')
                .then(({data}) => this.countries = data)
                .catch((error) => {
                    this.globalResponseMessage = "Something wrong happened. Check your Internet connection or try again later.";
                    this.globalResponseType = 'error';
                    this.globalResponse = true;
                });
            this.loadData(this.id, this.confirmation_code);
        },
        watch: {
            'form.email_formats': function (val) {
                if (val.length > 5) {
                    this.$nextTick(() => this.form.email_formats.pop())
                }
            }
        },
        methods: {
            loadData(id, confirmation_code) {
                axios.get('/api/pending-institutions/' + id + '?confirmation_code=' + confirmation_code)
                    .then((response) => {
                        this.form.country = response.data.country;
                        this.form.name = response.data.name;
                        this.form.email_formats = response.data.email_formats;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.globalResponseMessage = error.response.data.message;
                        this.globalResponseType = 'error';
                        this.globalResponse = true;
                    })
            },
            update() {
                this.$validator.validateAll('edit')
                    .then((result => {
                        if (result) {
                            this.submitIsLoading = true;
                            axios.put('/admin/institutions/pending/' + this.id, {
                                country_id: this.form.country,
                                name: this.form.name,
                                email_formats: this.form.email_formats
                            })
                                .then((response) => {
                                    this.$validator.reset({scope: 'edit'});
                                    this.loadData(this.id, this.confirmation_code);
                                    this.globalResponseMessage = response.data.message;
                                    this.globalResponseType = 'success';
                                    this.globalResponse = true;
                                })
                                .catch((error) => {
                                    if (error.response.data.message === "") {
                                        this.globalResponseMessage = "An unexpected error happened. We're working on it.";
                                    } else {
                                        this.globalResponseMessage = error.response.data.message;
                                    }
                                    this.globalResponseType = 'error';
                                    this.globalResponse = true;
                                    if (error.response.data.errors) {
                                        if (error.response.data.errors.country_id) {
                                            this.$validator.errors.add({
                                                field: 'country',
                                                msg: error.response.data.errors.country_id,
                                                scope: 'edit'
                                            });
                                        }
                                        if (error.response.data.errors.name) {
                                            this.$validator.errors.add({
                                                field: 'name',
                                                msg: error.response.data.errors.name,
                                                scope: 'edit'
                                            });
                                        }
                                        if (error.response.data.errors.email_formats) {
                                            this.$validator.errors.add({
                                                field: 'emailFormats',
                                                msg: error.response.data.errors.email_formats,
                                                scope: 'edit'
                                            });
                                        }
                                    }
                                })
                                .finally(() => {
                                    this.submitIsLoading = false;
                                })
                        }
                    }))
                    .catch(err => {
                        alert(err);
                    })
            }
        }
    }
</script>

<style scoped>

</style>
