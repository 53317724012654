<template>
    <v-card class="mb-3 px-3 pb-1 pt-1">
        <v-card-title class="pb-0 mb-0">
            <v-flex xs12 class="align-center">
                <h1 class="headline font-weight-light blue--text">
                    <slot></slot>
                </h1>
            </v-flex>
        </v-card-title>
        <v-card-text class="mt-0 pt-0">
            <v-container fluid grid-list-xl>
                <v-layout row wrap>
                    <v-flex md3 sm6 xs12>
                        <v-select
                            :error-messages="errors.collect('country')"
                            :items="countries"
                            :label="this.content.country"
                            clearable
                            color="blue"
                            item-text="name"
                            item-value="id"
                            return-object
                            single-line
                            v-model="countryField"
                            v-on:input="loadInstitutions"
                        ></v-select>
                    </v-flex>
                    <v-flex md9 sm6 xs12>
                        <v-autocomplete
                            :disabled="autocompleteIsDisabled"
                            :error-messages="errors.collect('institution')"
                            :items="institutions"
                            :label="this.content.institution"
                            :loading="isLoading"
                            :placeholder="autocompletePlaceholder"
                            clearable
                            color="blue"
                            item-text="name"
                            item-value="id"
                            return-object
                            single-line
                            v-model="institutionField"
                            v-on:input="loadSignatories"
                        ></v-autocomplete>
                    </v-flex>
                </v-layout>
                <v-data-table
                    :headers="headers"
                    :items="signatories"
                    :loading="tableIsLoading"
                    :rows-per-page-items="rowsPerPageItems"
                    hide-actions
                >
                    <template slot="items" slot-scope="props">
                        <td class="align-center">{{ props.item.name }}</td>
                        <td class="align-center" v-if="!institutionField">{{ props.item.institution }}</td>
                        <td class="align-center" v-if="!countryField">{{ props.item.country_name }}</td>
                    </template>
                </v-data-table>
                <div class="text-xs-center pt-2">
                    <v-pagination :length="pages" @input="loadSignatories" @next="loadSignatories"
                                  @prev="loadSignatories"
                                  class="main-blue-pagination"
                                  total-visible="5"
                                  v-model="pagination.page"
                    ></v-pagination>
                </div>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: {
            total: {
                type: Number,
                required: true
            },
            locale: {
                type: String
            }
        },
        data() {
            return {
                pagination: {
                    totalItems: 0,
                    rowsPerPage: 10,
                    page: 1
                },
                tableIsLoading: false,
                isLoading: false,
                countries: [],
                countryField: null,
                institutionField: null,
                institutions: [],
                autocompleteIsDisabled: true,
                autocompletePlaceholder: '',
                autocompleteIsLoading: false,
                signatories: [],
                rowsPerPageItems: [10, 20, 50, {"text": "$vuetify.dataIterator.rowsPerPageAll", "value": -1}],
                content: null,
                dictionary: {
                    en: {
                        content: {
                            country: "Institution's country",
                            institution: "Institution's name",
                            institutionSearch: "Search for an institution",
                            institutionSearchEmpty: "No institution from this country yet.",
                            headers: {
                                name: "Name",
                                institution: "Institution",
                                country: "Country"
                            }
                        }
                    },
                    fr: {
                        content: {
                            country: "Pays de l'établissement",
                            institution: "Nom de l'établissement",
                            institutionSearch: "Rechercher un établissement",
                            institutionSearchEmpty: "Pas d'établissement de ce pays pour le moment.",
                            headers: {
                                name: "Nom",
                                institution: "Etablissement",
                                country: "Pays"
                            }
                        }
                    },
                    es: {
                        content: {
                            country: "País",
                            institution: "Nombre de la universidad",
                            institutionSearch: "Busca una universidad",
                            institutionSearchEmpty: "No hay universidades de este país todavía.",
                            headers: {
                                name: "Nombre",
                                institution: "Universidad",
                                country: "País"
                            }
                        }
                    },
                    it: {
                        content: {
                            country: "Paese dell'istituzione",
                            institution: "Nome dell'istituzione",
                            institutionSearch: "Cerca un'istituzione",
                            institutionSearchEmpty: "Non ci sono ancora istituzioni per questo paese.",
                            headers: {
                                name: "Nome",
                                institution: "Istituzione",
                                country: "Paese"
                            }
                        }
                    }
                }
            }
        },
        created() {
            switch (this.locale) {
                case 'fr':
                    this.content = this.dictionary.fr.content;
                    this.$validator.localize('fr', this.dictionary.fr.errors);
                    break;
                case 'es':
                    this.content = this.dictionary.es.content;
                    this.$validator.localize('es', this.dictionary.es.errors);
                    break;
                case 'it':
                    this.content = this.dictionary.it.content;
                    this.$validator.localize('it', this.dictionary.it.errors);
                    break;
                default:
                    this.content = this.dictionary.en.content;
                    this.$validator.localize('en', this.dictionary.en.errors);
                    break;
            }
        },
        mounted() {
            this.pagination.totalItems = this.total;
            this.loadSignatories();
            axios.get('/api/countries', {
                params: {
                    locale: this.locale
                }
            })
                .then(({data}) => this.countries = data)
                .catch((error) => {
                    console.log(error);
                });
        },
        watch: {
            countryField(val) {
                if (!val) {
                    this.autocompleteIsDisabled = true;
                    this.autocompletePlaceholder = '';
                }
            }
        },
        methods: {
            loadSignatories() {
                this.tableIsLoading = true;
                axios.get('/api/signatories', {
                    params: {
                        offset: 10 * (this.pagination.page - 1),
                        limit: 10,
                        country_id: (this.countryField != null ? this.countryField.id : ''),
                        institution_id: (this.institutionField != null ? this.institutionField.id : ''),
                        locale: this.locale
                    }
                })
                    .then((response) => {
                        this.pagination.totalItems = response.data.total;
                        this.signatories = response.data.signatories;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.tableIsLoading = false
                    })
            },
            loadInstitutions () {
                this.loadSignatories();
                if (this.countryField) {
                    this.institutionField = null;
                    if (this.autocompleteIsLoading) return;
                    this.isLoading = true;

                    axios.get('/api/institutions', {
                        params : {
                            country_id: this.countryField.id
                        }
                    })
                        .then(({data}) => {
                            this.institutions = data
                        })
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            if (this.institutions.length > 0) {
                                this.autocompleteIsDisabled = false;
                                this.autocompletePlaceholder = this.content.institutionSearch;
                            } else {
                                this.autocompleteIsDisabled = true;
                                this.autocompletePlaceholder = this.content.institutionSearchEmpty;
                            }
                            this.isLoading = false;
                        });
                }
            },
            filter(signatories) {
                let self = this;
                if (this.countryField) {
                    if (this.institutionField) {
                        return signatories.filter(function(signatory) {
                            return signatory.country_name.indexOf(self.countryField.code) > -1 && signatory.institution.indexOf(self.institutionField.name) > -1
                        })
                    }
                    else {
                        return signatories.filter(function(signatory) {
                            return signatory.country_name.indexOf(self.countryField.code) > -1
                        })
                    }
                }
                else {
                    return signatories
                }
            }
        },
        computed: {
            pages() {
                if (this.pagination.rowsPerPage == null ||
                    this.pagination.totalItems == null
                ) return 0;

                return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
            },
            headers() {
                let name = {
                    text: this.content.headers.name,
                    value: 'name',
                    sortable: false,
                    align: 'center',
                    class: ['main-blue', 'white--text', 'white--icons'],
                    width: '33%'
                };
                let institution = {
                    text: this.content.headers.institution,
                    value: 'institution',
                    sortable: false,
                    align: 'center',
                    class: ['main-blue', 'white--text'],
                    width: '33%'
                };
                let country = {
                    text: this.content.headers.country,
                    value: 'country_name',
                    sortable: false,
                    align: 'center',
                    class: ['main-blue', 'white--text'],
                    width: '33%'
                };
                if (!this.countryField) {
                    return  [
                        name,
                        institution,
                        country
                    ]
                }
                else if (this.institutionField) {
                    return  [
                        name
                    ]
                }
                else {
                    return  [
                        name,
                        institution
                    ]
                }
            },
        }
    }
</script>

<style>
    .align-center {
        text-align: center
    }

    .theme--light.v-datatable thead th.column.sortable.active, .theme--light.v-datatable thead th.column.sortable.active .v-icon, .theme--light.v-datatable thead th.column.sortable:hover {
        color: white
    }
</style>
