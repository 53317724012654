import Vue from 'vue';
import './bootstrap';
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/HomepageFormsComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('homepage-forms', require('./components/HomepageFormsComponent.vue').default);
Vue.component('status-timeline', require('./components/StatusTimelineComponent.vue').default);
Vue.component('signatories-list', require('./components/SignatoriesListComponent.vue').default);
Vue.component('confirmation-form', require('./components/ConfirmationFormComponent.vue').default);
Vue.component('contact-form', require('./components/ContactFormComponent.vue').default);
Vue.component('edit-pending-institution-form', require('./components/EditPendingInstitutionComponent.vue').default);
Vue.component('terminate-pending-institution-form', require('./components/TerminatePendingInstitutionComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

new Vue({
    el: '#app',
    data() {
        return {
            drawer: false
        }
    }
});
